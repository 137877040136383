import React from 'react';
import { createRoot } from 'react-dom/client';
import AvatarCreator from "./creator/AvatarCreator";
import { FeatureFlippingContext } from './contexts/FeatureFlippingContext';

import 'whatwg-fetch';

export function mount(config) {
    const container = document.getElementById(config.containerId)
    const root = createRoot(container);
    return root.render(
        <React.StrictMode>
            <FeatureFlippingContext.Provider value={config.featureFlippingConfig}>
                <AvatarCreator {...config.props} />
            </FeatureFlippingContext.Provider>
        </React.StrictMode>
    );
}
