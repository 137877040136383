import React, {useEffect, useRef, useContext} from 'react';
import {createPortal} from 'react-dom';

import {categories} from "../config/features-v3";

import {FeatureFlippingContext} from '../contexts/FeatureFlippingContext';

const Avatar = ({selectedFeatures, displayDownload = false}) => {

    const featureFlipping = useContext(FeatureFlippingContext);

    const canvasRef = useRef(null);

    useEffect(() => {
        const drawAvatar = () => {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            context.fillStyle = '#FFFFFF';
            context.fillRect(0, 0, canvas.width, canvas.height);

            const loadImage = (selectedFeatureEntry) => {
                return new Promise((resolve, reject) => {
                    const categoryId = selectedFeatureEntry[0];
                    const featureId = selectedFeatureEntry[1];

                    const image = new Image();
                    const imageName = categories[categoryId].features[featureId].image
                    image.src = `../img/${categories[categoryId].path}/${imageName}`;
                    image.onload = () => {
                        resolve({categoryId, image});
                    };
                    image.onerror = (error) => {
                        reject(error);
                    };
                });
            };

            const loadFiligrane = () => {
                return new Promise((resolve, reject) => {
                    if (featureFlipping.beta) {
                        const filigrane = new Image();
                        filigrane.src = '../img/filigrane-beta.png'
                        filigrane.onload = () => {
                            resolve(filigrane);
                        }
                        filigrane.onerror = (error) => {
                            reject(error);
                        };
                    } else {
                        resolve(null);
                    }
                });
            }

            loadFiligrane()
                .then((filigrane) => {
                    if (filigrane) {
                        context.drawImage(filigrane, 0, 0);
                    }
                    Promise.all(Object.entries(selectedFeatures).map(loadImage))
                        .then((loadedImages) => {
                            loadedImages.forEach(({categoryId, image}) => {
                                context.drawImage(image, 0, 0);
                            });
                        })
                        .catch((error) => {
                            console.error('Error loading images:', error);
                        });
                })
                .catch((error) => {
                    console.error('Error loading filigrane:', error);
                });
        };
        drawAvatar();
    }, [selectedFeatures]);

    return (
        <>
            <canvas ref={canvasRef} width={1080} height={1400} className={'avatar__picture'}/>
            {displayDownload &&
                createPortal(
                    <button className="btn btn-secondary" href="#" onClick={() => {
                        const canvas = canvasRef.current;
                        const link = document.createElement('a');
                        link.download = `avatar-${new Date().getTime()}.png`;
                        link.href = canvas.toDataURL();
                        link.click();
                    }}>
                        <i className="maificon maificon-telecharger" aria-hidden="true"></i>
                        Télécharger l'image
                    </button>,
                    document.getElementById('avatar-download-button-container')
                )
            }
        </>
    );
};

export default Avatar;
