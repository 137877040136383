export const bodyFeatures = {
    1: {image: '1-2-01.svg', label: 'à définir'},
    2: {image: '1-2-02.svg', label: 'à définir'},
    3: {image: '1-2-03.svg', label: 'à définir'},
};

export const faceFeatures = {
    1: {image: '3-01.svg', label: 'à définir'},
    2: {image: '3-02.svg', label: 'à définir'},
    3: {image: '3-03.svg', label: 'à définir'},
    4: {image: '3-04.svg', label: 'à définir'},
    5: {image: '3-05.svg', label: 'à définir'},
    6: {image: '3-06.svg', label: 'à définir'},
    7: {image: '3-07.svg', label: 'à définir'},
    8: {image: '3-08.svg', label: 'à définir'},
    9: {image: '3-09.svg', label: 'à définir'},
};

export const eyesFeatures = {
    1: {image: '6-01.svg', label: 'à définir'},
    2: {image: '6-02.svg', label: 'à définir'},
    3: {image: '6-03.svg', label: 'à définir'},
    4: {image: '6-04.svg', label: 'à définir'},
    5: {image: '6-05.svg', label: 'à définir'},
    6: {image: '6-06.svg', label: 'à définir'},
};

export const beardFeatures = {
    0: {image: '4-00.svg', label: 'à définir'},
    1: {image: '4-01.svg', label: 'à définir'},
    2: {image: '4-02.svg', label: 'à définir'},
    3: {image: '4-03.svg', label: 'à définir'},
    4: {image: '4-04.svg', label: 'à définir'},
    5: {image: '4-05.svg', label: 'à définir'},
    6: {image: '4-06.svg', label: 'à définir'},
    7: {image: '4-07.svg', label: 'à définir'},
    8: {image: '4-08.svg', label: 'à définir'},
    9: {image: '4-09.svg', label: 'à définir'},
    10: {image: '4-10.svg', label: 'à définir'},
    11: {image: '4-11.svg', label: 'à définir'},
    12: {image: '4-12.svg', label: 'à définir'},
}

export const mouthFeatures = {
    1: {image: '5-01.svg', label: 'à définir'},
    2: {image: '5-02.svg', label: 'à définir'},
    3: {image: '5-03.svg', label: 'à définir'},
    4: {image: '5-04.svg', label: 'à définir'},
    5: {image: '5-05.svg', label: 'à définir'},
    6: {image: '5-06.svg', label: 'à définir'},
    7: {image: '5-07.svg', label: 'à définir'},
    8: {image: '5-08.svg', label: 'à définir'},
    9: {image: '5-09.svg', label: 'à définir'},
    10: {image: '5-10.svg', label: 'à définir'},
    11: {image: '5-11.svg', label: 'à définir'},
    12: {image: '5-12.svg', label: 'à définir'},
    13: {image: '5-13.svg', label: 'à définir'},
    14: {image: '5-14.svg', label: 'à définir'},
    15: {image: '5-15.svg', label: 'à définir'},
    16: {image: '5-16.svg', label: 'à définir'},
    17: {image: '5-17.svg', label: 'à définir'},
    18: {image: '5-18.svg', label: 'à définir'},
    19: {image: '5-19.svg', label: 'à définir'},
};

export const hairFeatures = {
    0: {image: '9-00.svg', label: 'à définir'},
    1: {image: '9-01.svg', label: 'à définir'},
    2: {image: '9-02.svg', label: 'à définir'},
    3: {image: '9-03.svg', label: 'à définir'},
    4: {image: '9-04.svg', label: 'à définir'},
    5: {image: '9-05.svg', label: 'à définir'},
    6: {image: '9-06.svg', label: 'à définir'},
    7: {image: '9-07.svg', label: 'à définir'},
    8: {image: '9-08.svg', label: 'à définir'},
    9: {image: '9-09.svg', label: 'à définir'},
    10: {image: '9-10.svg', label: 'à définir'},
    11: {image: '9-11.svg', label: 'à définir'},
    12: {image: '9-12.svg', label: 'à définir'},
    13: {image: '9-13.svg', label: 'à définir'},
    14: {image: '9-14.svg', label: 'à définir'},
    15: {image: '9-15.svg', label: 'à définir'},
    16: {image: '9-16.svg', label: 'à définir'},
    17: {image: '9-17.svg', label: 'à définir'},
    18: {image: '9-18.svg', label: 'à définir'},
    19: {image: '9-19.svg', label: 'à définir'},
    20: {image: '9-20.svg', label: 'à définir'},
    21: {image: '9-21.svg', label: 'à définir'},
    22: {image: '9-22.svg', label: 'à définir'},
    23: {image: '9-23.svg', label: 'à définir'},
    24: {image: '9-24.svg', label: 'à définir'},
    25: {image: '9-25.svg', label: 'à définir'},
    26: {image: '9-26.svg', label: 'à définir'},
    27: {image: '9-27.svg', label: 'à définir'},
    28: {image: '9-28.svg', label: 'à définir'},
    29: {image: '9-29.svg', label: 'à définir'},
    30: {image: '9-30.svg', label: 'à définir'},
    31: {image: '9-31.svg', label: 'à définir'},
    32: {image: '9-32.svg', label: 'à définir'},
    33: {image: '9-33.svg', label: 'à définir'},
    34: {image: '9-34.svg', label: 'à définir'},
    36: {image: '9-36.svg', label: 'à définir'},
    37: {image: '9-37.svg', label: 'à définir'},
    38: {image: '9-38.svg', label: 'à définir'},
    39: {image: '9-39.svg', label: 'à définir'},
    40: {image: '9-40.svg', label: 'à définir'},
    41: {image: '9-41.svg', label: 'à définir'},
    42: {image: '9-42.svg', label: 'à définir'},
    43: {image: '9-43.svg', label: 'à définir'},
    44: {image: '9-44.svg', label: 'à définir'},
    45: {image: '9-45.svg', label: 'à définir'},
    46: {image: '9-46.svg', label: 'à définir'},
    47: {image: '9-47.svg', label: 'à définir'},
    48: {image: '9-48.svg', label: 'à définir'},
    49: {image: '9-49.svg', label: 'à définir'},
    50: {image: '9-50.svg', label: 'à définir'},
    51: {image: '9-51.svg', label: 'à définir'},
    52: {image: '9-52.svg', label: 'à définir'},
    53: {image: '9-53.svg', label: 'à définir'},
    54: {image: '9-54.svg', label: 'à définir'},
    55: {image: '9-55.svg', label: 'à définir'},
    56: {image: '9-56.svg', label: 'à définir'},
    57: {image: '9-57.svg', label: 'à définir'},
    58: {image: '9-58.svg', label: 'à définir'},
    59: {image: '9-59.svg', label: 'à définir'},
    60: {image: '9-60.svg', label: 'à définir'},
    61: {image: '9-61.svg', label: 'à définir'},
    62: {image: '9-62.svg', label: 'à définir'},
    63: {image: '9-63.svg', label: 'à définir'},
    64: {image: '9-64.svg', label: 'à définir'},
    65: {image: '9-65.svg', label: 'à définir'},
    66: {image: '9-66.svg', label: 'à définir'},
    67: {image: '9-67.svg', label: 'à définir'},
    68: {image: '9-68.svg', label: 'à définir'},
    69: {image: '9-69.svg', label: 'à définir'},
    70: {image: '9-70.svg', label: 'à définir'},
    71: {image: '9-71.svg', label: 'à définir'},
    72: {image: '9-72.svg', label: 'à définir'},
    73: {image: '9-73.svg', label: 'à définir'},
    74: {image: '9-74.svg', label: 'à définir'},
    75: {image: '9-75.svg', label: 'à définir'},
    76: {image: '9-76.svg', label: 'à définir'},
    77: {image: '9-77.svg', label: 'à définir'},
    78: {image: '9-78.svg', label: 'à définir'},
    79: {image: '9-79.svg', label: 'à définir'},
    80: {image: '9-80.svg', label: 'à définir'},
    81: {image: '9-81.svg', label: 'à définir'},
    82: {image: '9-82.svg', label: 'à définir'},
};

export const expressionFeatures = {
    0: {image: '7-00.svg', label: 'à définir'},
    1: {image: '7-01.svg', label: 'à définir'},
    2: {image: '7-02.svg', label: 'à définir'},
    3: {image: '7-03.svg', label: 'à définir'},
    4: {image: '7-04.svg', label: 'à définir'},
    5: {image: '7-05.svg', label: 'à définir'},
    6: {image: '7-06.svg', label: 'à définir'},
    7: {image: '7-07.svg', label: 'à définir'},
    8: {image: '7-08.svg', label: 'à définir'},
    9: {image: '7-09.svg', label: 'à définir'},
    10: {image: '7-10.svg', label: 'à définir'},
    11: {image: '7-11.svg', label: 'à définir'},
    12: {image: '7-12.svg', label: 'à définir'},
    13: {image: '7-13.svg', label: 'à définir'},
    14: {image: '7-14.svg', label: 'à définir'},
    15: {image: '7-15.svg', label: 'à définir'},
    16: {image: '7-16.svg', label: 'à définir'},
    17: {image: '7-17.svg', label: 'à définir'},
    18: {image: '7-18.svg', label: 'à définir'},
    19: {image: '7-19.svg', label: 'à définir'},
    20: {image: '7-20.svg', label: 'à définir'},
};

export const glassesFeatures = {
    0: {image: '8-00.svg', label: 'à définir'},
    1: {image: '8-01.svg', label: 'à définir'},
    2: {image: '8-02.svg', label: 'à définir'},
    3: {image: '8-03.svg', label: 'à définir'},
    4: {image: '8-04.svg', label: 'à définir'},
    5: {image: '8-05.svg', label: 'à définir'},
    6: {image: '8-06.svg', label: 'à définir'},
    7: {image: '8-07.svg', label: 'à définir'},
    8: {image: '8-08.svg', label: 'à définir'},
    9: {image: '8-09.svg', label: 'à définir'},
    10: {image: '8-10.svg', label: 'à définir'},
    11: {image: '8-11.svg', label: 'à définir'},
    12: {image: '8-12.svg', label: 'à définir'},
    13: {image: '8-13.svg', label: 'à définir'},
}

export const categories = {
    face: {name: 'visage', features: faceFeatures, path: 'avatar-features/v3/3-visages-min'},
    hair: {name: 'cheveux', features: hairFeatures, path: 'avatar-features/v3/9-coiffures-min'},
    eyes: {name: 'yeux', features: eyesFeatures, path: 'avatar-features/v3/6-yeux-min'},
    mouth: {name: 'bouche', features: mouthFeatures, path: 'avatar-features/v3/5-bouches-min'},
    beard: {name: 'barbe', features: beardFeatures, path: 'avatar-features/v3/4-barbes-moustaches-min'},
    expression: {name: 'expression', features: expressionFeatures, path: 'avatar-features/v3/7-sourcils-min'},
    glasses: {name: 'lunettes', features: glassesFeatures, path: 'avatar-features/v3/8-lunettes-min'},
    body: {name: 'corps', features: bodyFeatures, path: 'avatar-features/v3/1-2-epaule-cou-min'},
};

export const defaultSelectedFeatures = {
    body: 1,
    face: 1,
    eyes: 1,
    beard: 0,
    mouth: 1,
    hair: 0,
    expression: 0,
    glasses: 0
}

export const sanityzeSelectedFeatures = (dirtySelectedFeatures) => {

    const sanityzeFeatureId = (id, features) => {
        const allowedIds = Object.keys(features).map(i => parseInt(i, 10));
        if (allowedIds.includes(id)) {
            return id;
        } else {
            return parseInt(allowedIds[0], 10);
        }
    }

    return {
        body: sanityzeFeatureId(dirtySelectedFeatures.body, bodyFeatures),
        face: sanityzeFeatureId(dirtySelectedFeatures.face, faceFeatures),
        eyes: sanityzeFeatureId(dirtySelectedFeatures.eyes, eyesFeatures),
        beard: sanityzeFeatureId(dirtySelectedFeatures.beard, beardFeatures),
        mouth: sanityzeFeatureId(dirtySelectedFeatures.mouth, mouthFeatures),
        hair: sanityzeFeatureId(dirtySelectedFeatures.hair, hairFeatures),
        expression: sanityzeFeatureId(dirtySelectedFeatures.expression, expressionFeatures),
        glasses: sanityzeFeatureId(dirtySelectedFeatures.glasses, glassesFeatures),
    }
}
