import React from 'react';
import {capitalize} from "../utils/capitalize";

const Features = ({category, selectedFeature, onSelect, path}) => {

    const createImageGrid = () => {
        const rows = [];

        const row = Object.entries(category.features).map((featureEntry) => {
            const featureId = featureEntry[0];
            const feature = featureEntry[1];
            return (
                <div className={`${'' + selectedFeature === featureId ? 'custom__entry__list__entry selected ' : ' custom__entry__list__entry'}`} key={featureId} onClick={() => onSelect(featureId)}>
                    <img src={`${path}/${feature.image}`} alt={feature.label} width={300}
                         role={"button"}/>
                </div>
            )
        });
        rows.push(
            <div className="custom__entry__list-content">
                <div className="custom__entry__list">
                    {row}
                </div>
            </div>
        );
        
        return rows;
    };

    return (
        <div className="tab-content">
            <div className="custom__entry__title">
                <h2>{capitalize(category.name)}</h2>
                <p>Choisissez une typologie de {category.name} :</p>
            </div>
            {createImageGrid()}
        </div>
    );
};

export default Features;