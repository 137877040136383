import React, {useState} from 'react';

import {
    defaultSelectedFeatures,
    sanityzeSelectedFeatures
} from "../config/features-v3";

import Categories from "./Categories";
import Avatar from "../components/Avatar";

const AvatarCreator = ({ initialFeaturesIds, onEnd }) => {

    const [selectedFeatures, setSelectedFeatures] = useState(sanityzeSelectedFeatures(initialFeaturesIds));

    const selectFeature = (categoryId, featureId) => {
        setSelectedFeatures((prevSelectedFeatures) => ({
            ...prevSelectedFeatures,
            [categoryId]: parseInt(featureId, 10),
        }));
    };

    const resetFeatures = () => {
        setSelectedFeatures(defaultSelectedFeatures);
    }

    const onCreatorEnd = () => {
        onEnd(selectedFeatures)
    }

    return (
        <div id={"app"} className='creator__app'>
            <div className='creator__app__avatar'>
                <div className='avatar__picture__container'>
                    <Avatar selectedFeatures={selectedFeatures} />
                </div>
            </div>
            <div className='creator__app__custom'>
                <Categories selectedFeatures={selectedFeatures} selectFeature={selectFeature} resetFeatures={resetFeatures} onCreatorEnd={onCreatorEnd}/>
            </div>
        </div>
    );
};

export default AvatarCreator;
