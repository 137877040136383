import React, {useState} from 'react';
import {categories} from "../config/features-v3";
import Features from "./Features";

const categoriesEntries = Object.entries(categories);
const defaultCategoryId = categoriesEntries[0][0];

const Categories = ({selectedFeatures, selectFeature, resetFeatures, onCreatorEnd}) => {

    const [selectedCategory, setSelectedCategory] = useState(defaultCategoryId);

    const onResetFeaturesClicked = () => {
        resetFeatures();
        setSelectedCategory(defaultCategoryId);
    }

    return (
        <div className="creator__app__custom__container">
            <div className="creator__app__custom__category">
                <button className="left-button">
                    <i className="maificon maificon-arrow-left" aria-hidden="true"></i>
                </button>
                <div className="creator__app__custom__category__list-container">
                    <ul className="creator__app__custom__category__list" id="creator__app__custom__category__list">
                        {
                            categoriesEntries.map((categoriesEntry) => {
                                const categoryId = categoriesEntry[0];
                                const category = categoriesEntry[1];
                                return (
                                    <li className="creator__app__custom__category__list-entry" key={categoryId}>
                                        <a className={`${selectedCategory === categoryId ? 'active' : ''}`}
                                        role={"button"}
                                        onClick={() => setSelectedCategory(categoryId)}>{category.name}</a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <button className="right-button">
                    <i className="maificon maificon-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
            <div className="creator__app__custom__entry">
                {
                    categoriesEntries.map((categoriesEntry) => {
                        const categoryId = categoriesEntry[0];
                        const category = categoriesEntry[1];
                        if (categoryId === selectedCategory) {
                            return <Features category={category}
                                             selectedFeature={selectedFeatures[categoryId]}
                                             onSelect={(featureId) => selectFeature(categoryId, featureId)}
                                             path={`../img/${category.path}`}
                            />;
                        }
                    })
                }
                <div className="custom__entry__action">
                    <button className="btn btn-secondary" onClick={onResetFeaturesClicked}>
                        <i className="maificon maificon-fleche-return"></i> Réinitialiser
                    </button>
                    <button className="btn btn-primary" onClick={onCreatorEnd}>
                        <i className="maificon maificon-arrow-right"></i> Terminer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Categories;